@import url("https://use.typekit.net/nsk2yda.css");
@import "~antd/dist/antd.less";
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg404 {
  background-image: url("./assets/images/404.jpg");
}
.header-bg {
  background-image: url("./assets/images/header-bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f29f05;
  height: 290px;
  z-index: 1111;
}
.race {
  background-image: url("./assets/images/race.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.eqbg {
  background-image: url("./assets/images/eqbg3.png");
  background-position: center center;
  background-repeat: repeat;
  background-color: #212121 !important;
}
.anticon svg {
  display: block;
}
.ant-progress-inner {
  background-color: white !important;
  border: 1px solid #ddd;
}
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 18px !important;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 1px solid #ddd;
  outline: 0;
  border-radius: 4px;
  background: white;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0em;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  color: black !important;
  font-weight: bold;
  font-size: large;
}
.Demo__some-network {
  display: inline;
  margin-right: 3px;
}
.react-share__ShareButton svg {
  border-radius: 3px !important;
}
.login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-image: url("./assets/images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.main-menum.ant-menu-horizontal {
  justify-content: right !important;
  line-height: 50px !important;
  margin-left: -15px;
  margin-right: -15px;
  font-size: large;
  text-transform: uppercase;
  // background-color: rgba(0, 0, 0, 0.1) !important;
  // background-color: #854516 !important;
  //box-shadow: 0 0 5px #ddd;
  background-color: transparent;
}

.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout-content {
  min-height: calc(100vh - 188px);
}
.ant-layout-header {
  height: unset !important;
  background-color: transparent;
  padding: 0 !important;
  z-index: 111111;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-menu-item-selected {
  font-weight: 700;
  background-color: transparent !important;
}
.amenu {
  background-color: transparent;
  margin-top: 10px;
}
.ant-menu-item {
  padding: 0 10px !important;
}

.amenu .ant-menu-item-selected {
  background-color: white !important;
  border-top: 1px solid #ddd !important;
  border-bottom: 1px solid #ddd !important;
}
.ant-drawer-body {
  padding: 0;
}
.ant-menu-horizontal {
  border-bottom: none;
}
.desktop-sider {
  margin-top: 64px;
  background-color: #fafafa;
}
.mobil-sider {
  position: absolute;
  z-index: 1;
  min-height: 100%;
  border-right: 1px solid #ddd;
  background-color: #f5f7f9;
}
.ant-layout {
  // background-image: url("./assets/images/main-bg.jpg");
  // background-repeat: repeat;
  background-color: #eeeeee;
}
.footer {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;

  background-color: #fafafa;
  padding: 15px 0 15px 0;
}
.signatureCanvas {
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
}
.nested .ant-table table {
  background-color: #f0f9ff !important;
}
.nested .ant-table-thead > tr > th {
  background-color: #e0f2fe !important;
  border-bottom: 1px solid #ccc;
  color: #0891b2 !important;
  font-weight: 600;
}
.ant-descriptions-item-label {
  font-size: large !important;
}
.ant-descriptions-item-content {
  font-size: large !important;
}
.ant-image-preview-img {
  display: initial !important;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@primary-color: #0EA5E9;